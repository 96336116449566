import {autoinject, bindable} from "aurelia-framework";
import {FhirService} from "../../../resources/services/FhirService";
import {I18N} from "aurelia-i18n";
import {GrafixxItem, IGrafixxItem} from "../../../resources/classes/Grafixx-item";
import * as moment from "moment";
import {translations} from "../../../resources/classes/translations";
import {NitTools} from "../../../resources/classes/NursitTools";
import {fhirEnums} from "../../../resources/classes/fhir-enums";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {IThumb, IThumbGroup, WoundGroup, WoundGroupItem} from "./wound-main";
import {App} from "../../../app";
import {ConfigService} from "../../../resources/services/ConfigService";

@autoinject
export class WoundDataHandler {
    static BodyMarkersCodeSystem: fhir4.CodeSystem;
    woundIcons: { name: string, source: string }[] = [];
    thumbnails: IThumbGroup[]
    isLoading: boolean = false;
    currentBodyPart: string;
    @bindable observations: any[];
    woundGroupLists: any[];
    observationsBundled: boolean;
    groups: any[];
    protected fhirService: FhirService;
    private __is3dBody: boolean = undefined;

    constructor(protected i18n: I18N) {
        this.fhirService = new FhirService(FhirService.Endpoint);
    }

    get thumbNailList(): IThumb[] {
        if (!this.thumbnails) return [];
        const result: IThumb[] = [];
        for (const group of this.thumbnails) {
            result.push(...group.items);
        }

        return result;
    }

    get is3dBody(): boolean {
        if (typeof this.__is3dBody === "undefined") {
            const cfg = ConfigService.GetFormSettings('wounds');
            this.__is3dBody = cfg?.settings?.body3d?.enabled === true;
        }

        return this.__is3dBody;
    }

    async generateGroups(): Promise<any[]> {
        this.groups = [];
        if (!this.is3dBody) {
            GrafixxItem.Default.forEach((grafixxItem: IGrafixxItem) => {
                let group = new WoundGroup(grafixxItem);
                group.children = [];
                this.groups.push(group);
            });
        } else {
            await this.loadIcons();
        }

        if (!this.observations) return;

        // move the observations into the groups
        const filteredObservations: fhir4.Observation[] = this.observations.filter(o => o.category?.[0]?.text);
        for (const observation of filteredObservations) {
            if (observation.category[0]?.text) {
                let txt = observation.category[0].text;
                let grp: WoundGroup = this.groups.find(o => o.item.type === txt);
                if (this.is3dBody && !grp) {
                    grp = new WoundGroup({
                        type: txt,
                        sum: 0,
                        imageName: '',
                        text: this.i18n.tr(txt),
                        svg: '',
                        items: []
                    });

                    let img = document.createElement("img");
                    img.src = this.woundIcons.find(o => o.name === txt)?.source;
                    if (!img.src || String(img.src).endsWith('undefined')) {
                        let imgSrc = txt;
                        imgSrc = imgSrc.replace('wound_', '');
                        let imageUrl = `images/bodies/icons/${imgSrc}.svg`;
                        console.warn(`Concept ${txt} seems to have no icon. Falling back to svg file: ${imageUrl}`);

                        try {
                            img = NitTools.SvgToImage(await NitTools.LoadSvg(imageUrl));
                        }
                        catch(ex) {
                            console.warn(`Error when loading ${imageUrl}: ${ex}`);
                            try {
                                imgSrc = `images/bodies/icons/fallback_warning.svg`;
                                img = NitTools.SvgToImage(await NitTools.LoadSvg(imgSrc));
                            }
                            catch(ex2) {
                                console.warn(`Error when loading fallback "${imgSrc}": ${ex2}`);
                                img = undefined;
                            }
                        }
                    }

                    grp.groupIcon = img;

                    this.groups.push(grp);
                }

                if (grp) {
                    let wgi = new WoundGroupItem(observation);

                    if (wgi.valid) {
                        grp.children.push(wgi);
                    } else if (ConfigService.Debug) {
                        console.warn('Invalid WoundGroupItem:', wgi);
                    }
                } else {
                    if (ConfigService.Debug)
                        console.warn(`No group found with name "${txt}". Aviable are: `, this.groups.map(g => g.item.type));
                }
            }
        }

        //console.warn('Observations:', this.observations);

        // set visibility of group
        this.groups = this.groups.filter(o => o.children && o.children.length > 0);

        //sort the items by id
        for (const grp of this.groups) {
            for (const child of grp.children) {
                const idx = grp.children.indexOf(child);
                if (!child.item?.identifier) {
                    child.item.identifier = [
                        {
                            value: idx
                        }
                    ]

                    child.listId = idx;
                }
            }

            // sort by identifier if possible (which is the number, displayed in the icon)
            grp.children.sort((a: WoundGroupItem, b: WoundGroupItem) => {
                let bId = 10000;
                let aId = 9999;
                if (b && b.item && b.item.identifier && b.item.identifier[0] && b.item.identifier[0].value) bId = parseInt(b.item.identifier[0].value);
                if (a && a.item && a.item.identifier && a.item.identifier[0] && a.item.identifier[0].value) aId = parseInt(a.item.identifier[0].value);

                return aId - bId;
            })
        }

        this.assignIconsToGroups();

        return this.groups;
    }

    getObservationById(id: string): any {
        if (typeof id === "object") return id;

        if (id.indexOf('/') > -1) id = id.split('/')[1];
        let obs = this.observations.find(o => o.id === id);

        return obs || id;
    }

    async createThumbGrouping(fhirThumbs): Promise<IThumbGroup[]> {
        const thumbs = [];
        if (fhirThumbs) {
            fhirThumbs.sort((a, b) => {
                try {
                    return new Date((<any>b.content).creation).valueOf() - new Date((<any>a.content).creation).valueOf()
                } catch (err) {
                    console.warn(err);
                    return 0;
                }
            })

            for (const t of fhirThumbs) {
                let groupTitle = moment((<any>t.content).creation).format(translations.translate("date_format"));
                let printId = this.fhirService.tags.value(t, 'PrintImage');

                let imageData = "data:" + t.content.contentType + ";base64," + t.content.data;
                let thumb: IThumb = {
                    title: (<any>t.content).title,
                    imageUrl: (<any>t.content).url,
                    resource: t,
                    print: printId && printId != 'false',
                    thumb: imageData,
                    thumbEdited: ""
                };

                let grp: IThumbGroup = thumbs.find(o => o.title === groupTitle);
                if (!grp) {
                    grp = {
                        title: groupTitle,
                        items: []
                    };

                    thumbs.push(grp);
                }

                // do a distinct thumb adding
                if (typeof grp.items.find(o => o.resource.id === thumb.resource.id) === "undefined") {
                    grp.items.push(thumb);
                }
            }
        }

        return thumbs;
    }

    bundleObservationsToGroups() {
        //if (this.observationsBundled) return;
        this.observationsBundled = true;
        const filteredGroups = this.groups.filter(g => g.item && g.item.type);

        for (const group of filteredGroups) {
            let woundType = group.item.type;
            let listGroupsForGroup = this.woundGroupLists.filter(o => o.type === woundType);
            for (const listGroup of listGroupsForGroup) {
                for (let i = 0; i < listGroup.observations.length; i++) {
                    let observationId = listGroup.observations[i];
                    let obs = this.observations.find(o => o.id === observationId);

                    // move the observation from observations into the group item
                    if (obs) {
                        let idx = this.observations.indexOf(obs);
                        listGroup.observations[i] = NitTools.Clone(obs);
                        // yyyyyy this.observations.splice(idx, 1);

                        // observation exists and has been moved to the listGroup, so now remove the item from the group
                        let existing = group.children.find(o => o.item && o.item.id === observationId);
                        if (existing) {
                            idx = group.children.indexOf(existing);
                            group.children.splice(idx, 1);
                        }
                    }
                }

                /* just to be sure solve the references from the listGroup.observations once more */
                if (!listGroup.observations) listGroup.observations = [];

                let s = "";
                for (let i = 0; i < listGroup.observations.length; i++) {
                    listGroup.observations[i] = this.getObservationById(listGroup.observations[i]);

                    try {
                        if (typeof listGroup.observations[i] === "object" && listGroup.observations[i].bodySite && listGroup.observations[i].bodySite.coding)
                            s += listGroup.observations[i].bodySite.coding[0].display;
                    } catch (e) {
                        console.warn(e.message);
                    }
                }

                let arr = listGroup.observations.map((obs: any) => {
                    return obs && obs.bodySite ? this.i18n.tr(obs.bodySite.coding[0].code || obs.bodySite.coding[0].display) : ''
                });

                listGroup.title = listGroup.title || arr.join(' + ').replace(/ \+  \+ /g, '').trim();
                let hintText = listGroup.title;

                if (listGroup.title.length > 42) {
                    listGroup.title = listGroup.title.substr(0, 40) + "..";
                }

                // now add a new item to the group to display the grouped list.
                let newGroupItem: WoundGroupItem = {
                    bodyPart: "area",
                    image: "body-2.0",
                    imagePart: "-",
                    listId: listGroup.listId,
                    item: listGroup,
                    left: -1,
                    longText: listGroup.title || "Area",
                    hintText: hintText,
                    shortText: 'M',
                    top: -1,
                    valid: true,
                }

                if (typeof group.children.find(o => o.listId === listGroup.listId) === "undefined") {
                    group.children.push(newGroupItem);
                    if (this.is3dBody) {
                        newGroupItem.shortText = String(group.children.indexOf(newGroupItem) + 1);
                    }
                }
            }
        }

        for (const g of this.groups) {
            for (const child of g.children.filter(o => o.item)) {
                const obs = child.item.observations && child.item.observations[0] ? child.item.observations[0] : child.item;

                child.isHealed = typeof obs?.effectivePeriod?.end !== "undefined";
            }
        }

        return this.groups;
    }

    async loadObservations(encounterId: string, resetLoadedObservations: boolean): Promise<any[]> {
        if (resetLoadedObservations) {
            this.observations = undefined;
        }

        if (this.observations && this.observations.length > 0) {
            return this.observations;
        }

        try {
            let uri = `${fhirEnums.ResourceType.observation}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${encounterId}&status:not=cancelled,entered-in-error`;
            if (this.is3dBody) {
                uri += '&code=marker';
            }

            const result = <any[]>await this.fhirService.fetch(uri);

            // rework the new 3d body to the old format. As we do not write the Observation Resource in the wounds page, this should be ok
            for (const obs of result) {
                if (!obs.category && NitTools.IsArray(obs.component)) {
                    obs.category = [];
                    if (ConfigService.Debug)
                        console.debug("Creating compatibility in category-property for Observation: ", obs);
                    for (const comp of obs.component.filter(c => c.code?.coding)) {
                        for (const coding of comp.code.coding) {
                            if (coding.code) {
                                if (coding.code === "wounds") coding.code = "wound";

                                obs.category.push({text: coding.code})
                            }
                        }
                    }
                }
            }

            this.observations = result.filter(o =>
                ((o.category && o.category[0] && o.category[0].text) || (NitTools.IsArray(o.component) && o.component[0]))
                && (o.status !== fhirEnums.ObservationStatus.cancelled && o.status !== fhirEnums.ObservationStatus.enteredInError));

            await this.generateGroups();
        } catch (error) {
            console.warn(error);
        }

        return this.observations;
    }

    async loadWoundGroups(encounterId: string): Promise<any> {
        this.woundGroupLists = [];

        let result = await this.fhirService.fetch(`List?encounter=${encounterId}&code=${RuntimeInfo.SystemHeader}/body-map-group|`);
        for (const group of result) {
            let typeItem = group.code.coding.find(o => o.system === RuntimeInfo.SystemHeader + "/body-map-group");
            let type = "other";
            if (typeItem && typeItem.code) type = typeItem.code;
            this.woundGroupLists.push({
                listId: group.id,
                type: type,
                title: group.title,
                observations: (<any[]>group.entry).map(e => {
                    return e.item.reference.split('/')[1]
                })
            })
        }

        this.bundleObservationsToGroups();

        this.assignIconsToGroups();
        return this.woundGroupLists;
    }

    async loadThumbNails(encounterId, woundGroupItem): Promise<IThumbGroup[]> {
        this.thumbnails = [];

        try {
            const isWoundGroupSelected = typeof woundGroupItem.listId !== "undefined";
            let bodyPart = isWoundGroupSelected ? 'area' : woundGroupItem.item.bodySite.coding[0].display; // this.selectedWound.bodySite.coding[0].display; //.split('_')[1];
            this.currentBodyPart = bodyPart;
            this.isLoading = true;
            let url = `Media?_format=json`
                + `&${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${encounterId}`
                + `&type=${FhirService.FhirVersion > 3 ? 'image' : 'photo'}`
                + `&${FhirService.FhirVersion > 3 ? 'modality' : 'subtype'}=thumbnail`
                + `&identifier=${woundGroupItem.listId || woundGroupItem.item.id}`;

            /* load faulty thumbs */
            let fhirThumbs_2: any[] = <any[]>await this.fhirService.fetch(url);
            let fhirThumbs: any[] = []; // <any[]>await this.fhirService.fetch(url);
            fhirThumbs_2.forEach(t => fhirThumbs.push(t));

            this.thumbnails = await this.createThumbGrouping(fhirThumbs)
        } catch (e) {
            console.warn(e.message);
        } finally {
            this.isLoading = false;
        }

        return this.thumbnails;
    }

    async ensure3dCodeSystem(): Promise<fhir4.CodeSystem> {
        if (!this.is3dBody) return undefined;

        if (!WoundDataHandler.BodyMarkersCodeSystem) {
            const csUrl = `CodeSystem?system=http://nursit-institute.com/fhir/StructureDefinition/body-markers`;
            const fs = new FhirService();
            const arr = await fs.fetch(csUrl);
            WoundDataHandler.BodyMarkersCodeSystem = arr?.[0];
        }

        return WoundDataHandler.BodyMarkersCodeSystem;
    }

    async loadIcons(): Promise<any> {
        try {
            if (this.is3dBody) {
                this.woundIcons = [];
                const cs = await this.ensure3dCodeSystem();
                for (const concept of cs.concept.filter(c => c.code && c.extension?.length > 0)) {
                    const iconExtension = concept.extension.find(o => o.url.endsWith('/codesystem-attachment') && o.valueAttachment?.data);
                    if (String(iconExtension?.valueAttachment?.contentType).startsWith('image/')) {
                        const data = `data:${iconExtension.valueAttachment.contentType};base64,${iconExtension.valueAttachment.data}`;
                        this.woundIcons.push({name: concept.code, source: data});
                    }
                }

                return Promise.resolve(this.woundIcons);
            }

            let icons = []; // ['circle', 'diamond', 'pentagon', 'square', 'star', 'triangle-down', 'triangle-up'];
            await GrafixxItem.Init(App.i18n);
            GrafixxItem.Default.forEach(item => icons.push(item.imageName));
            if (this.woundIcons.length === icons.length) return Promise.resolve(this.woundIcons);
            for (let i = 0; i < icons.length; i++) {
                let iconSvg = await NitTools.LoadSvg(`./images/bodies/icons/${icons[i]}.svg?_=${new Date().valueOf()}`);
                this.woundIcons.push({
                    name: icons[i],
                    source: NitTools.SvgToBase64ImageSource(iconSvg)
                })
            }

            return Promise.resolve(this.woundIcons);
        } catch (e) {
            console.warn(e.message);
            return Promise.reject("Could not load Icon-SVGs. Message: " + e.message);
        }
    }

    assignIconsToGroups() {
        if (!this.groups || this.groups.length === 0 || !this.woundIcons || this.woundIcons.length === 0) return;

        for (const group of this.groups) {
            if (group.item && group.item.imageName) {
                const img = this.woundIcons.find(o => o.name === group.item.imageName);
                if (img) {
                    group.groupIcon = {
                        src: img.source
                    }
                }
            }
        }
    }

    loadWounds(encounterId: string): Promise<WoundGroup[]> {
        return new Promise<any>(async (resolve, reject) => {
            if (!encounterId) return resolve([]);
            this.isLoading = true;
            await this.loadIcons();
            try {
                await this.loadObservations(encounterId, true);
                await this.loadWoundGroups(encounterId)
                // now go through the groups and bundle the responses containted in a woundGroupList for this group into a single item
                this.bundleObservationsToGroups();
                this.assignIconsToGroups();

                resolve(this.groups);
            } catch (error) {
                console.warn(error);
                reject("Error when loading Observations in loading Wounds")
            } finally {
                this.isLoading = false;
            }
        })
    }
}
